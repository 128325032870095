<template>
  <div class="container1">
    <div class="container2">
      <img src="@/assets/img/404.png" alt="404" />
      <div class="container3">
        <div>
          <div class="title">404</div>
          <div class="msg">没有页面，或者用户没有权限！</div>
          <el-button type="primary" round @click="backToHome">返回首页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  methods: {
    backToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container2 {
  display: flex;
  justify-content: space-between;
}
.container3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 60px;
  color: #409eff;
}
.msg {
  font-size: 30px;
  margin-bottom: 20px;
}
</style>
